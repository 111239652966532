@import '~leaflet/dist/leaflet.css';
@import '~modern-normalize/modern-normalize.css';
@import '~leaflet-draw/dist/leaflet.draw.css';

html,
body {
  width: 100%;
  height: 100%;
}


.app-root {
  width: 100%;
  height: 100%;
  position: relative;
}

.map-container {
  width: 100%;
  height: 100%;
}

.login {
  &__container {
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    background: #627148;
    color: #fff;
  }

  &__title {
    text-align: center;
  }

  &__holder {
    width: 300px;
    max-width: 90%;
  }
}

.login-form {
  width: 100%;

  &__label {
    display: block;
    font-size: 16px;
    margin: 0 0 5px 0;
  }

  &__control {
    height: 40px;
    padding: 0 12px;
    outline: none;
    border: 3px solid #464C54;
    width: 100%;
  }

  &__f-holder {
    margin: 0 0 15px 0;
  }

  &__error {
    margin: 5px 0 0 0;
    font-size: 13px;
    padding: 3px;
    border: 1px solid #CF6679;
    background: rgba(#CF6679, 0.7);
    color: #fff;

    &_top {
      margin: 0 0 10px 0;
    }
  }

  &__s-holder {
    text-align: center;
  }

  &__submit {
    padding: 0 25px;
    height: 40px;
    border: 3px solid #464C54;
    background: rgba(#464C54, 0.7);
    color: #fff;
    cursor: pointer;
    user-select: none;

    &:hover {
      background: rgba(#464C54, 0.5);
    }

    &:disabled {
      cursor: default;
      pointer-events: none;
      opacity: 0.7;
    }
  }
}

.detecting-location {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.8);
  z-index: 999;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  color: #464C54;
}

.form-page {
  background: #627148;
  color: #fff;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
  overflow: auto;
  flex-flow: column nowrap;

  &__back {
    position: absolute;
    right: 10px;
    top: 10px;
    color: #fff;
    font-size: 14px;

    &:hover {
      text-decoration: none;
    }
  }

  &__form-holder {
    width: 400px;
    max-width: 90%;
    min-height: 0;
  }
}

.main-nav {
  position: absolute;
  right: 10px;
  top: 10px;
  z-index: 8999;
  display: flex;

}

.add-link {
  background: #627148;
  color: #fff;
  text-decoration: none;
  cursor: pointer;
  display: block;
  padding: 5px 10px;
  border-radius: 30px;
  font-size: 14px;

  +.add-link {
    margin: 0 0 0 15px;
  }
}

.add-form {
  width: 100%;
  padding: 30px 0;

  &__grid {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    margin: 0 -5px;
    position: relative;
  }

  &__grid-item {
    margin: 0 5px 15px;
  }

  &__radio {
    display: inline-flex;
    align-items: center;

    input {
      margin: 0 4px 0 0;
    }
  }

  &__radio+&__radio {
    margin: 0 0 0 20px;
  }

  &__label {
    display: block;
    font-size: 16px;
    margin: 0 0 5px 0;
  }

  &__control {
    height: 40px;
    padding: 0 12px;
    outline: none;
    border: 3px solid #464C54;
    width: 100%;

    &_multi {
      height: auto;
      padding: 0;
    }

    &_area {
      padding: 5px 12px;
      min-height: 100px;
    }
  }

  &__f-holder {
    margin: 0 0 15px 0;
  }

  &__error {
    margin: 5px 0 0 0;
    font-size: 13px;
    padding: 3px;
    border: 1px solid #CF6679;
    background: rgba(#CF6679, 0.7);
    color: #fff;

    &_top {
      margin: 0 0 10px 0;
    }
  }

  &__s-holder {
    text-align: center;
  }

  &__s-holder + &__s-holder {
    margin: 15px 0 0 0;
  }

  &__del {
    position: absolute;
    left: 100%;
    top: 35px;
    cursor: pointer;
  }

  &__submit {
    padding: 0 25px;
    height: 40px;
    border: 3px solid #464C54;
    background: rgba(#464C54, 0.7);
    color: #fff;
    cursor: pointer;
    user-select: none;

    &:hover {
      background: rgba(#464C54, 0.5);
    }

    &:disabled {
      cursor: default;
      pointer-events: none;
      opacity: 0.7;
    }
  }
}

path.leaflet-interactive {
  outline: none;
}

.info-tooltip {
  line-height: 120%;
  &__title {
    text-align: center;
    font-weight: bold;
  }
}


.area-c-form {
  margin: 15px 0 0 0;

  &__line {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__line + &__line {
    margin: 20px 0 0 0;
  }

  &__control {
    width: 60px;
    margin: 0 5px;
    border: 2px solid #464C54;
    height: 30px;
  }

  &__btn {
    padding: 0 5px;
    height: 30px;
    border: 2px solid #464C54;
    background: #627148;
    color: #fff;
    cursor: pointer;
    user-select: none;

    &_activate {
      margin: 0 5px 0 0;
    }

    &_delete {
      background: #CF6679;
    }

    &:hover {
      background: rgba(#627148, 0.8);
    }

    &:disabled {
      cursor: default;
      pointer-events: none;
      opacity: 0.7;
    }
  }
}